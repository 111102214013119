import React from "react";

//Import HashLink for anchor tags link to sections on another page correctly
import { HashLink as Link } from 'react-router-hash-link';

//Import logos
import MasseyLogo from "../images/masseylogo.jpg";
import KEHLogo from "../images/kehlogo.jpg";
import WebsiteLogo from "../images/websitelogo.png";
import GDPLiveLogo from "../images/gdplivelogo.png";

/**
 * Functional Component Menu
 *  - Display the nav menu above the banner of the dashboard
 * 
 *  Props
 *    - ChangeViewButtonClick : function passed from Header component, used to swap dashboard views onClick
 * 
 * @param {*} props 
 */
function Menu(props) {
  return (
    <div>
      {/* Start of Top Menu */}

      {/* End of Top Menu */}

      {/* Start of Transparent Menu */}

      <div className="wrapper row1">

        <header id="header" className="hoc clear menu">
          <div className="fl_left" style={{ paddingTop: "15px", paddingRight: "15px" }}>

            <ul className="nospace">
              <a href="/">
                <img id="gdplivelogo" src={GDPLiveLogo} alt="Home" />
              </a>
            </ul>
          </div>

          <div className="fl_right" style={{ paddingTop: "15px" }}>
            <ul className="nospace">

              <a href="https://www.massey.ac.nz/">
                <img id="masseylogo" src={MasseyLogo} alt="Massey University Logo" />
              </a>
              <a href="http://www.massey.ac.nz/massey/initiatives/enterprise/auckland-knowledge-exchange/auckland-knowledge-exchange_home.cfm">
                <img id="kehlogo" src={KEHLogo} alt="Knowledge Exchange Hub Logo" />
              </a>
            </ul>
          </div>

          <nav id="mainav" className="fl_right">
            <ul className="clear">


              <li>
                <a className="drop" href="">Dimensions</a>
                <ul>
                  <li><Link to="/Dashboard#map">
                    National Predictions
                  </Link>
                  </li>
                  <li>
                    <Link to="/Dashboard#map" >
                      Regional Prediction
                    </Link>
                  </li>
                  <li>
                    <Link to="/Dashboard#industry">
                      Industry Sector Predictions
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/Dashboard#report"> Publications</Link>
              </li>

              <li>
                <a className="drop" href="">
                  Technical Details
                </a>
                <ul>
                  <li>
                    {/* FIXME  */}
                    <Link to="/about#partners">Data Inputs</Link>
                  </li>
                  <li>
                    <Link to="/TechnicalDetails#algorithms">
                      Machine Learning Algorithms
                    </Link>
                  </li>
                  <li>
                    <Link to="/TechnicalDetails#previouspredictions">
                      Previous Predictions
                    </Link>
                  </li>
                  <li>
                    <Link to="/TechnicalDetails#limitations">Limitations</Link>
                  </li>
                </ul>
              </li>

              <li>
                <a className="drop" href="">About</a>
                <ul>
                  <li>
                    <Link to="/about#aims">Aims</Link>
                  </li>
                  <li>
                    <Link to="/about#index">GDP Index</Link>
                  </li>
                  <li>
                    <Link to="/about#team">Project Team</Link>
                  </li>
                  <li>
                    <Link to="/about#partners">Partners</Link>
                  </li>
                </ul>
              </li>

              {/* End of the menu list */}
            </ul>
          </nav>

        </header>

      </div>
      {/* End of Transparent Menu */}

    </div>

  );

}

export default Menu;
