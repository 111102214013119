//Import react
import React, { Component } from "react";
import { MDBRow, MDBCol, MDBIcon } from "mdbreact";
import { HashLink as Link } from 'react-router-hash-link';
import { Link as Link2 } from 'react-router-dom'
import Popup from 'reactjs-popup';
import Modal from 'react-responsive-modal';
import { FaInfoCircle } from 'react-icons/fa';


import '../../node_modules/react-accessible-accordion/dist/fancy-example.css';

//Import main stylesheet
import "../styles/popups.css";
//import console = require("console");
//import console = require("console");


const Card = ({ title, info }) => (
    <div className="card">
        <div className="header"><b>{title}</b></div>
        <div className="content">
            {/*info */}
            <div dangerouslySetInnerHTML={{ __html: info }} />
        </div>
    </div>
);

/*const CreateToolTip = ({ title, info }) => (
    <Popup
        trigger={<MDBIcon
            icon="info-circle"
            size="1x"
        />}
        position="top center"
        on="hover"
    >
        <Card title={title} info={info} />
    </Popup>
);*/

const CreateToolTip = ({ title, info }) => {
    return (
        < Popup
            trigger={<button className="button" style={{ background: 'none', border: 'none' }}>
                <FaInfoCircle size="1.2em" />
            </button>}
            position="top center"
        >
            <div>
                <h3>{title}</h3>
                <p>{info}</p>
            </div>
        </Popup >
    );
};



class DimensionTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            graphTitle: "",
            idx: 1,
            previewImage: null,
        };
    }

    ChangeGraph(title, index, image) {
        //Check that this is a new graph
        if (title !== this.state.graphTitle) {
            this.setState({ graphTitle: title, idx: index, previewImage: image });
        }
    }

    /** on Component Mount, create a string array of Plotly Url's and save to state
     *  use these url's to retrieve preview images of graphs from /public folder
     */
    componentWillMount() {

    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };


    RenderIndicator(graphIndex) {
        /**
         * Render a single row in the table
         * 
         * Each row onClick changes the graph above this component using function passed through props
         * 
         * Each image in the row has a hover event which calls the DisplayHoverGraph function
         */

        let urlParametrs = ".embed?link=false&modebar=false&autosize=True&width=100%";
        let jpegParameters = ".jpeg?link=false&modebar=false&autosize=True&width=350&height=170";
        let plotlyPath = this.props.IndicatorData['Indicators'][this.props.IndicatorData['Website']['IndicatorGraphs'][graphIndex]]['plotly_file_path'];
        const { open } = this.state;
        let idx = this.state.idx;
        let plotlyFileName = ''

        if (idx == 0) {
            idx = idx + 1;
            plotlyFileName = this.props.IndicatorData['plotly_file_path'] + urlParametrs;
        } else {
            plotlyFileName = this.props.IndicatorData['Indicators'][this.props.IndicatorData['Website']['IndicatorGraphs'][this.state.idx]]['plotly_file_path'] + urlParametrs;
        }

        return (
            <div className="wrapper row3" style={{ alignItems: "left", alignContent: "left" }} key={graphIndex}>
                <MDBRow>
                    <MDBCol md="9"><h5 className="font-xs" style={{ color: "#474747" }}> {this.props.IndicatorData['Website']['IndicatorGraphs'][graphIndex]} </h5>
                    </MDBCol>


                    <MDBCol md="1">
                        <a onClick={() => this.ChangeGraph(this.props.IndicatorData['Website']['IndicatorGraphs'][graphIndex], graphIndex, null)}
                            style={{ display: "block", color: "black" }}
                            id={this.props.IndicatorData['Website']['IndicatorGraphs'][graphIndex]}
                        >
                            <MDBIcon
                                icon="expand"
                                size="1x"
                                className="pink-text "
                                onClick={this.onOpenModal}

                            ></MDBIcon> </a>
                        <Modal className="styles_modal__gNwvD" onClose={this.onCloseModal} open={open} center >

                            <h2> {this.props.IndicatorData['Website']['IndicatorGraphs'][this.state.idx]} </h2>
                            <a id="graph" />
                            <iframe className="iframe" frameBorder="0" scrolling="yes" src={plotlyFileName}></iframe>
                        </Modal>
                    </MDBCol>


                    <MDBCol md="1">
                        <CreateToolTip title={this.props.IndicatorData['Website']['IndicatorGraphs'][graphIndex]}
                            info={this.props.IndicatorData['Indicators'][this.props.IndicatorData['Website']['IndicatorGraphs'][graphIndex]]['Description'] + '<br>' +
                                "<b>Rationale:</b> " + this.props.IndicatorData['Indicators'][this.props.IndicatorData['Website']['IndicatorGraphs'][graphIndex]]['Rationale'] + '<br>' +
                                "<b>Weight:</b> " + this.props.IndicatorData['Indicators'][this.props.IndicatorData['Website']['IndicatorGraphs'][graphIndex]]['Weight'] + '<br>' +
                                "<b>Data Source:</b> " + this.props.IndicatorData['Indicators'][this.props.IndicatorData['Website']['IndicatorGraphs'][graphIndex]]['Source'] + '<br>' +
                                "<b>No. of values:</b> " + this.props.IndicatorData['Indicators'][this.props.IndicatorData['Website']['IndicatorGraphs'][graphIndex]]['No. of values'] + '<br>' +
                                "<b>Year of last recorded value:</b> " + this.props.IndicatorData['Indicators'][this.props.IndicatorData['Website']['IndicatorGraphs'][graphIndex]]['Year of last recorded value'] + '<br>' +
                                "<b>Min value:</b> " + this.props.IndicatorData['Indicators'][this.props.IndicatorData['Website']['IndicatorGraphs'][graphIndex]]['Min value'] + '<br>' +
                                "<b>Max value:</b> " + this.props.IndicatorData['Indicators'][this.props.IndicatorData['Website']['IndicatorGraphs'][graphIndex]]['Max value']

                            } />
                    </MDBCol>
                </MDBRow>
                <section
                    style={{ paddingTop: "0px", paddingLeft: "0px", alignItems: "left" }}>
                    <a id="graph" />
                    <iframe className="iframe" frameBorder="0" scrolling="no"
                        src={plotlyPath + jpegParameters} ></iframe>
                </section>
            </div>
        );
    }

    render() {

        const { open } = this.state;
        let indicatorGraphSections = [];
        //console.log(this.props['Website']['IndicatorGraphs'])
        //console.log(this.props['Website']['IndicatorGraphs'])
        for (let i = 1; i < this.props.IndicatorData['Website']['IndicatorGraphs'].length - 1; i++) {
            indicatorGraphSections.push(
                this.RenderIndicator(i)
            );
        }

        let descriptionText = " There are " + Object.keys(this.props.IndicatorData['Indicators']).length.toString() + " indicators that contribute to this dimension."
        //console.log("AAAAAAAAAAAAAAAAAAAAA:   " + this.props.DimensionKey)
        return (


            <section>
                {/*  */}
                <main className="hoc container clear">
                    <section>


                        <li className="one_third first">
                            <article>
                                <h4 className="font-xs font-x2" style={{ color: "#474747" }}>About {this.props.IndicatorData['OfficialTitle']}</h4>
                                <div dangerouslySetInnerHTML={{ __html: this.props.IndicatorData['Website']['About'] }} />
                            </article>
                        </li>

                        <li className="one_third">
                            <article>
                                <h4 className="font-xs font-x2" style={{ color: "#474747" }}>Key Findings</h4>
                                <div dangerouslySetInnerHTML={{ __html: this.props.IndicatorData['Website']['KeyFindings'] }} />
                            </article>
                        </li>

                        <li className="one_third" style={{ boxAlign: "left", alignItems: "left", alignContent: "left" }}>

                            <div className="wrapper row3" style={{ alignItems: "left", alignContent: "left" }}>
                                <MDBRow>
                                    <MDBCol md="9"><h4 className="font-xs font-x2" style={{ color: "#474747" }}>{this.props.IndicatorData['OfficialTitle']} Index</h4>
                                    </MDBCol>

                                    <MDBCol md="1">
                                        <a onClick={() => this.ChangeGraph(this.props.IndicatorData['Website']['IndicatorGraphs'][0], 0, null)}
                                            style={{ display: "block", color: "black" }}
                                            id="Housing Index"
                                        >
                                            <MDBIcon
                                                icon="expand"
                                                size="1x"
                                                className="pink-text "
                                                onClick={this.onOpenModal}

                                            ></MDBIcon> </a>
                                        <Modal className="styles_modal__gNwvD" onClose={this.onCloseModal} open={open} center >

                                            <h2> "{this.props.IndicatorData['OfficialTitle']} Index"</h2>
                                            <a id="graph" />
                                            <iframe className="iframe" frameBorder="0" scrolling="yes" src={this.props.IndicatorData['plotly_file_path'] + ".embed?link=false&modebar=false&autosize=True&height=100%"}></iframe>
                                        </Modal>
                                    </MDBCol>



                                    <MDBCol md="1">
                                        <CreateToolTip title={this.props.IndicatorData['OfficialTitle'] + " Index"}
                                            info={this.props.IndicatorData['Description'] + descriptionText} />
                                    </MDBCol>
                                </MDBRow>

                                <section
                                    style={{ paddingTop: "0px", paddingLeft: "0px", alignItems: "left" }}>
                                    <a id="graph" />
                                    <iframe className="iframe" frameBorder="0" scrolling="yes" src={this.props.IndicatorData['plotly_file_path'] + ".embed?link=false&modebar=false&autosize=True&width=100%"}></iframe>
                                </section>
                            </div>

                            <h4 className="font-xs font-x2" style={{ color: "#474747" }}>Key Indicators</h4>
                            {indicatorGraphSections}

                            {/*<Link className="btn inverse" to={"/About#aims?ind=" + this.props.IndicatorData['OfficialTitle']} 
                                                                //{{ pathname:"/About#aims",
                                                                //search: "?dim=Pov",
                                                             //state:{ test: true} 
                                                            //}}
                                                            >
                                See all dimension graphs
                        </Link>
                        <a className="btn inverse" href="/About">
                         &raquo;
                        </a>
                        */}

                            <Link2 className="btn inverse" to=
                                {{
                                    pathname: "/CompleteDimensionGraphs",
                                    data: this.props.DimensionKey
                                    //search: "?dim=Pov",
                                    //state:{ test: true} 
                                    //"/About#aims?ind=" + this.props.IndicatorData['OfficialTitle']
                                    //{"/About#aims?ind=" + this.props.IndicatorData['OfficialTitle']}
                                }}
                            >
                                See all indicators
                            </Link2>
                        </li>
                    </section>
                </main>
            </section>

        );
    }
}

const ColoredLine = ({ spec }) => (
    <hr
        style={{
            color: spec.color,
            backgroundColor: spec.color,
            height: parseInt(spec.hight)
        }}
    />
);

export default DimensionTemplate;
