import React, { Component } from "react";


//Import components
import Menu from "./components/Menu.js";
import Graph from "./components/Graph.js";
import Footer from "./components/Footer.js";

//Import scrollToElement library
import scrollToElement from "scroll-to-element";


/**
 * Component InDepthView
 *  - component for showing figures for the inputs in a larger size for more in depth analysis
 * 
 *  Props:
 *    - location.url :  the plotly index of the graph being displayed, passed in from dashboard
 *    - location.title :  the title of the graph being displayed, passed in from dashboard
 *    - location.preview :  the preview image of the graph being displayed, passed in from dashboard
 *     
 */


class PlotlyDashboardTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageHeight: "30000px",
    }
  }

  /**
   * Get Selector for "top" anchor tag.
   *  Use scrollToElement library to automatically scroll webpage to top on component load
   */
  componentDidMount() {
    let elem = document.querySelector("#top");
    scrollToElement(elem, {
      offset: 0,
      ease: "linear",
      duration: 100
    });

    if(window.screen.width < 450){
      this.setState({pageHeight: "400px"});
    }

    else if(window.screen.width < 1000){
      this.setState({pageHeight: "600px"});
    }

  }

  render() {
    return (
      <div>
        <a id="top" />
        {/* Menu Component */}
        <Menu ChangeViewButtonClick={this.props.ChangeViewButtonClick} />
        <div className="wrapper row3">
          <section className="hoc container clear" style={{ height: this.state.pageHeight, paddingTop: "20px" }}>
            
            <a>
            <iframe title='Labour Pillar' width="1100" height="800" frameborder="0" scrolling="no" src="//plot.ly/~tsusnjak/479.embed"></iframe>

            {/*<iframe src="http://127.0.0.1:8050/" width="1100" height="600" style={{border: "none"}}></iframe>*/}
            </a>

{/* INCOME DISTRIBUTION*/}            
            <a>
            <iframe title='Income Distribution Pillar' src="https://plot.ly/dashboard/tsusnjak:409/embed" width="1100" height="4400" style={{border: "none"}}></iframe>
            </a>

{/* CHILD POVERTY*/}
           <a>
            <iframe title='Deprivation and Child Pillar' src="https://plot.ly/dashboard/tsusnjak:438/embed" width="1100" height="4400" style={{border: "none"}}></iframe>
            </a>            
          

{/* HOUSING*/}
           <a>
            <iframe title='Housing Pillar' src="https://plot.ly/dashboard/tsusnjak:451/embed" width="1100" height="2750" style={{border: "none"}}></iframe>
            </a>   


{/* LABOUR*/}
           <a>
            <iframe title='Labour Pillar' src="https://plot.ly/dashboard/tsusnjak:468/embed" width="1100" height="3130" style={{border: "none"}}></iframe>
            </a>   

{/* GENDER INEQUALITY*/}
           <a>
            <iframe title='Gender Pillar' src="https://plot.ly/dashboard/tsusnjak:478/embed" width="1100" height="1700" style={{border: "none"}}></iframe>
            </a>   

{/* REGIONAL INEQUALITIES*/}
           <a>
            <iframe title='Regional Pillar' src="https://plot.ly/dashboard/tsusnjak:477/embed" width="1100" height="700" style={{border: "none"}}></iframe>
            </a>               

{/* EDUCATION */}
           <a>
            <iframe title='Education Pillar' src="https://plot.ly/dashboard/tsusnjak:513/embed" width="1100" height="2700" style={{border: "none"}}></iframe>
            </a>               


{/* HEALTH*/}

           <a>
            <iframe title='Health Pillar' src="https://plot.ly/dashboard/tsusnjak:514/embed" width="1100" height="2700" style={{border: "none"}}></iframe>
            </a>       

{/* SECURITY AND SAFETY*/}
           <a>
            <iframe title='Safety and Security Pillar' src="https://plot.ly/dashboard/tsusnjak:515/embed" width="1100" height="2700" style={{border: "none"}}></iframe>
            </a>               


          </section>

        </div>
        {/* Footer Component "https://plot.ly/dashboard/tsusnjak:303/embed" http://127.0.0.1:8050/ */}
        <Footer />
      </div>
    );
  }
}

export default PlotlyDashboardTest;
