import React from 'react';

import '../styles/App.css';

function TrendUp(props){
        return(
            <svg fill="green" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path className = "blinker" d="M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z">
            </path>
            <path d="M0 0h24v24H0z" fill="none"/>
        </svg>
        )
    }

export default TrendUp;