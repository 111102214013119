//React Imports
import React from 'react';
import ReactDOM from 'react-dom';

//Log Rocket setup
import LogRocket from 'logrocket';


//Import Base App Component
import App from './App';

//Import Components from external libaries
// import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter } from 'react-router-dom';
// import ThemeProvider from '@material-ui/styles/ThemeProvider';

//Import Boostrap
import Boostrap from 'bootstrap/dist/css/bootstrap.css';

//Change domain based on whether app is in production or development
import config from './config.json';
import indicators from './indicators.json';

var domain = config["domain"];
var domain_test = config["domain_test"];
var domain_local = config["domain_local"];
var IndicatorData = indicators;



require('dotenv').config();
var base_url;
if (process.env.REACT_APP_ENV === 'test') {
  base_url = domain_test
}
else if (process.env.REACT_APP_ENV === 'local') {
  base_url = domain_local
}
else {
  base_url = domain
}
console.log('Server Location');
console.log(base_url);


/**
 * WARNING: The server must be running ("node server.js") for the app to work in development
 * 
 * 
 * 
 * perform an axios get request to get the json file from /public folder
 * delay rendering of app until request is completed, call app component in the callback
 * 
 * this slows down app a bit in development, does not seem to influence load times in production however
 * see if we can find a better way to do this in the future
 * 
 * pass data from json file as a prop for the app component, from there data is passed down the component tree
 * 
 * if JSON Data could not be retrieved, render app with error message displaying instead of a black screen
 */

mainFunction()

async function mainFunction() {

  try {
    ReactDOM.render((
      <BrowserRouter>
        <App IndicatorData={IndicatorData} />
      </BrowserRouter>
    ), document.getElementById('root'))
  }
  catch (error) {
    ReactDOM.render((
      <BrowserRouter>
        <App err={error.toString()} />
      </BrowserRouter>
    ), document.getElementById('root'))
  }
}
