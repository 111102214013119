// Import react
import React, { useEffect } from "react";

//Import app-level stylesheet
import "./styles/App.css";

//Import from external libraries
import { Route, Redirect, useLocation } from "react-router-dom";

// Import components

import Dashboard from "./components/Dashboard.js";
import About from "./About.js";
import InDepthView from "./InDepthView.js";
import PlotlyDashboardTest from "./PlotlyDashboardTest.js";
import CompleteDimensionGraphs from "./CompleteDimensionGraphs.js";
import MainArticle from "./MainArticle.js";
import ga4 from 'react-ga4'

require('dotenv').config();

const ga4_measurement_id = process.env.REACT_APP_GA4_MEASUREMETN_ID;

const PageViewTracker = () => {
  const location = useLocation();

  useEffect(() => {
    console.log("PageViewTracker");
    console.log(location.pathname);
    console.log(document.title)
    ga4.send({ hitType: "pageview", page: location.pathname, title: location.pathname });

  }, [location]);

  return null; // This component does not render anything
};

/**
 * App Component
 *
 * Props
 *  PredictionData: JSON data containing predictions
 *  
 *  PlotlyIndex: JSON data containing plotly indexes
 *
 */

const App = ({ err, IndicatorData }) => {
  useEffect(() => {
    ga4.initialize(ga4_measurement_id)// Replace with your GTM ID
  }, []);

  if (err) {
    return <h1> {err} </h1>;
  } else {
    return (
      <div>
        <PageViewTracker />
        {/* Redirect all users to dashboard on site access */}
        <Route exact path="/" render={() => <Redirect to="/Dashboard" />} />

        {/* Pass in JSON data to dashboard component */}
        <Route path="/Dashboard" render={() => <Dashboard IndicatorData={IndicatorData}></Dashboard>} />
        {/* Subpages to show information in greater detail then what is shown on dashboard  */}
        <Route path="/About" render={() => <About />} />
        <Route path="/MainArticle" render={() => <MainArticle />} />
        {/*<Route path="/view" render={() => <InDepthView />} />
        <Route path="/PlotlyDashboard" render={() => <PlotlyDashboardTest />} />
        <Route path="/CompleteDimensionGraphs" render={() => <CompleteDimensionGraphs />} />
        */}

      </div>
    );
  }
}

export default App;