import React, { Component } from "react";


//Import components
import Menu from "./components/Menu.js";
import Graph from "./components/Graph.js";
import Footer from "./components/Footer.js";

//Import scrollToElement library
import scrollToElement from "scroll-to-element";

/**
 * Component InDepthView
 *  - component for showing figures for the inputs in a larger size for more in depth analysis
 * 
 *  Props:
 *    - location.url :  the plotly index of the graph being displayed, passed in from dashboard
 *    - location.title :  the title of the graph being displayed, passed in from dashboard
 *    - location.preview :  the preview image of the graph being displayed, passed in from dashboard
 *     
 */


class InDepthView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageHeight: "800px",
    }
  }

  /**
   * Get Selector for "top" anchor tag.
   *  Use scrollToElement library to automatically scroll webpage to top on component load
   */
  componentDidMount() {
    let elem = document.querySelector("#top");
    scrollToElement(elem, {
      offset: 0,
      ease: "linear",
      duration: 100
    });

    if(window.screen.width < 450){
      this.setState({pageHeight: "400px"});
    }

    else if(window.screen.width < 1000){
      this.setState({pageHeight: "600px"});
    }

  }

  render() {
    return (
      <div>
        <a id="top" />
        {/* Menu Component */}
        <Menu ChangeViewButtonClick={this.props.ChangeViewButtonClick} />
        <div className="wrapper row3">
          <section className="hoc container clear" style={{ height: this.state.pageHeight, paddingTop: "20px" }}>
            <a id="graph" />

            <Graph
              plotlyConnection={true}
              graphIndex={this.props.location.url}
              title={this.props.location.title}
              preview={this.props.location.preview}
              isMobileDevice = {true}
            />
          </section>
        </div>
        {/* Footer Component */}
        <Footer />
      </div>
    );
  }
}

export default InDepthView;
