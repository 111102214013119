//import react
import React from "react";

//Import blinking arrows
import TrendUp from "../images/TrendUp.js";
import TrendDown from "../images/TrendDown.js";

//Import moment to get current date
import moment from "moment";

//Import components
import Graph from "./Graph.js";

/**
 * GraphHover Component
 *  - Used for graphs displayed on hover in the Inputs, Industry and Regional sections
 * 
 *  Props:
 *     
 *      - id : the id used for css
 *       
 *      - top, left : the top and left position of the component
 * 
 *       - display : display state of the graph. Set to none initially and then set to "inline" on hover
 * 
 *       - graphTitle, previewImage : the title and preview image used for the component
 * 
 *       - GDP : float value showing GDP percentage change for that particular industry/region.
 *       
 *       - displayPreview : determines whether or not to display a preview only
 * 
 * @param {*} props 
 */

function GraphHover(props) {
  // create local vars to be assigned too
  let TrendArrow, graph = null;

  //get current data using moment
  var time = moment().format("MMMM Do YYYY");

  //if displayPreview is false, show graph, otherwise show preview image
  if (!props.displayPreview) {
    graph = <Graph />;
  } else {
    graph = (
      <img
        className = "previewImage"
        src={props.previewImage}
        alt="preview of graph"
      />
    );
  }

//Check if GDP has value, change the arrow which shows on the graph depending on GDP value
  if (props.GDP != null) {
    let GDPData = parseFloat(props.GDP);
    if (GDPData > 0) {
      TrendArrow = <TrendUp />;
    } else {
      TrendArrow = <TrendDown />;
    }
  }

  return (
    <div className="hoverGraph" id = {props.id} style={{ display: props.display, left: props.left, top: props.top }}>
        <h3>{props.graphTitle}</h3>
        <p> {TrendArrow} {props.GDP}</p>
        <br/>
        <h3 style = {{fontSize: "11px"}}> Generated on {time} </h3>
        {graph}
    </div>
  );
}

export default GraphHover;
