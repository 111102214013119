import React from "react";

//import CSS
//import '../styles/layout.css';
import {
  Card, Button, CardImg, CardTitle, CardText, CardDeck,
  CardSubtitle, CardBody, CardHeader, CardFooter, Container, Row
} from 'reactstrap';

//import placeholder image
import Profile1 from "../images/teoprofile2.jpg";
import Profile2 from "../images/christophprofile.jpg";

import Aj from "../images/aj.jpg";
import Rachel from "../images/rachel.jpg";
import John from "../images/john.jpg";
import Alison from "../images/alison.jpg";
import Jeremy from "../images/jeremy.jpg";
import Paul from "../images/paul.png";


//import static images
// import RyanProfile from "../images/ryanprofile.jpg";
// import TeoProfile from "../images/teoprofile.jpg";
// import WilliamProfile from "../images/williamprofile.png";

/**
 * Functional Component ProjectTeam
 *  - Display details about the project development team
 * 
 */

function ProjectTeam(props) {
  return (
    <div className="wrapper row3">
      <a id="team" />
      <main className="hoc container clear">


        <div className="group excerpts">

          {/* TODO: Ask Social media colors */}
          <CardDeck>
            <Row>
              <Card className="teamcard">
                <CardHeader>
                  <CardTitle style={{ fontSize: "25px", textAlign: "center" }}> Christoph Schumacher</CardTitle>
                </CardHeader>
                <img style={{ height: "250px", width: "250px", marginLeft: "auto", marginRight: "auto", marginTop: "5px" }} src={Profile2} alt="Card image cap" />
                <CardBody>
                  <CardText>
                    Christoph Schumacher is Professor in Innovation and Economics at Massey University and director of the Knowledge Exchange Hub. He holds a PhD in Economics and under- and post graduate degrees in Engineering and International Business from Germany and New Zealand. Christoph has previously worked as a business consultant and been an advisor to local government, district health boards and industry.
                    <br />
                    <br />
                    Christoph's area of specialisation is game theory with research interests in mathematical economics, machine learning, health and welfare economics, and experimental game theory. He has published in top international journals such as the Journal of Health Economics, Applied Economics, the European Journal of Marketing, the Journal of Industrial Economics and Economics Letters.
                  </CardText>
                </CardBody>
                <CardFooter className="text-muted">
                  {<footer style={{ textAlign: "center" }}>
                    <ul className="faico clear"></ul>
                  </footer>}
                </CardFooter>
              </Card>


              <Card className="teamcard">
                <CardHeader>
                  <CardTitle style={{ fontSize: "25px", textAlign: "center" }}>Teo Susnjak</CardTitle>
                </CardHeader>
                <img id="teamimage" style={{ height: "250px", width: "250px", marginLeft: "auto", marginRight: "auto", marginTop: "5px" }} src={Profile1} alt="Card image cap" />
                <CardBody>
                  <CardText>
                    Teo Susnjak holds a PhD in Computer Science in which the central research topic was machine learning. He is a Senior Lecturer with the Computer Science and IT group at Massey University. Teo developed and introduced the first Data Science specialisation at the undergraduate level out of all NZ and Australian universities. He both leads the Data Science specialisation and teaches its courses, as well as undertakes supervision of Masters and PhD students in this area of study.
                    <br />
                    <br />
                    Teo has worked in the industry as a machine learning analyst and he continues to focus his research on applying machine learning to solve real-world challenges from a wide range of domains.
                  </CardText>
                </CardBody>
                <CardFooter className="text-muted">
                  <footer style={{ textAlign: "center", }}>
                    <ul className="faico clear"></ul>
                  </footer>
                </CardFooter>
              </Card>
            </Row>

            <Row>
              <Card className="teamcard">
                <CardHeader>
                  <CardTitle style={{ fontSize: "25px", textAlign: "center" }}>Ajmol Ali</CardTitle>
                </CardHeader>
                <img id="teamimage" style={{ height: "250px", width: "250px", marginLeft: "auto", marginRight: "auto", marginTop: "5px" }} src={Aj} alt="Card image cap" />
                <CardBody>
                  <CardText>
                    Dr Ajmol Ali is an Associate Professor in the School of Sport, Exercise and Nutrition at Massey University. He is an expert in sports nutrition, sports science, and physical activity. Ajmol was instrumental in setting up the Sport and Exercise Science Laboratory at Auckland, is a director of the Centre for Metabolic Health Research and director of Beverage Lab.
                  </CardText>
                </CardBody>
                <CardFooter className="text-muted">
                  <footer style={{ textAlign: "center", }}>
                    <ul className="faico clear"></ul>
                  </footer>
                </CardFooter>
              </Card>
              <Card className="teamcard">
                <CardHeader>
                  <CardTitle style={{ fontSize: "25px", textAlign: "center" }}>Alison Brook</CardTitle>
                </CardHeader>
                <img id="teamimage" style={{ height: "250px", width: "250px", marginLeft: "auto", marginRight: "auto", marginTop: "5px" }} src={Alison} alt="Card image cap" />
                <CardBody>
                  <CardText>
                    Alison Brook is the business manager and content producer for the Knowledge Exchange Hub. She holds under-graduate degrees in law and business and a post graduate qualification in publishing. She has worked as marketing manager in the legal industry and as editor and publisher for multi-national publishing companies.
                  </CardText>
                </CardBody>
                <CardFooter className="text-muted">
                  <footer style={{ textAlign: "center", }}>
                    <ul className="faico clear"></ul>
                  </footer>
                </CardFooter>
              </Card>
            </Row>



            <Row>
              <Card className="teamcard">
                <CardHeader>
                  <CardTitle style={{ fontSize: "25px", textAlign: "center" }}>Paul Geertsema</CardTitle>
                </CardHeader>
                <img id="teamimage" style={{ height: "250px", width: "250px", marginLeft: "auto", marginRight: "auto", marginTop: "5px" }} src={Paul} alt="Card image cap" />
                <CardBody>
                  <CardText>
                    Paul Geertsema is a finance academic and consultant in the areas of <b>finance</b>, <b>data science</b> and <b>machine learning</b>. His research interests include empirical asset pricing, return predictability, portfolio optimization and the application of machine learning to finance problems. He is currently teaching <a href="https://www.google.com/url?q=https%3A%2F%2Fwww.courses.business.auckland.ac.nz%2FCoursePdfs%2FFINANCE361.pdf&sa=D&sntz=1&usg=AFQjCNHRWJvYZhRChlTuxPd4ShVcuOUrRw">Modern Investment Theory and Management</a> (final-year undergraduate) and <a href="https://www.google.com/url?q=https%3A%2F%2Fcourses.business.auckland.ac.nz%2FCoursePdfs%2FFINANCE781.pdf&sa=D&sntz=1&usg=AFQjCNHB1BUkx_CVTXJaKaAlE8pgsuttyA">Financial Machine Learning</a> (post-graduate) at the University of Auckland Business School. Previously Paul worked at Barclays Capital as a credit derivatives trader in Hong Kong and as a sell-side research analyst in London.
                  </CardText>
                </CardBody>
                <CardFooter className="text-muted">
                  <footer style={{ textAlign: "center", }}>
                    <ul className="faico clear">
                    </ul>
                  </footer>
                </CardFooter>
              </Card>


              <Card className="teamcard">
                <CardHeader>
                  <CardTitle style={{ fontSize: "25px", textAlign: "center" }}>John Matthewson</CardTitle>
                </CardHeader>
                <img id="teamimage" style={{ height: "250px", width: "250px", marginLeft: "auto", marginRight: "auto", marginTop: "5px" }} src={John} alt="Card image cap" />
                <CardBody>
                  <CardText>
                    John Matthewson works in the philosophy programme at Massey University. His primary research areas are philosophy of science and medicine, with a particular interest regarding the role that values play in scientific investigation and its implementation. His primary tasks were to make underlying theoretical commitments and decisions of the project explicit, and to pick at everyone else’s work.
                  </CardText>

                </CardBody>
                <CardFooter className="text-muted">
                  <footer style={{ textAlign: "center", }}>
                    <ul className="faico clear"></ul>
                  </footer>
                </CardFooter>
              </Card>
            </Row>

            <Row>
              <Card className="teamcard">
                <CardHeader>
                  <CardTitle style={{ fontSize: "25px", textAlign: "center" }}>Rachel Owens</CardTitle>
                </CardHeader>
                <img id="teamimage" style={{ height: "250px", width: "250px", marginLeft: "auto", marginRight: "auto", marginTop: "5px" }} src={Rachel} alt="Card image cap" />
                <CardBody>
                  <CardText>
                    Rachel Owens holds a Masters in Political Studies and a BSc in Data Science.  She lectures in data science and analytics at Massey University and works as a contractor on analytical projects.  Rachel has worked as a business analyst, investigator and data analyst and has also raised a family.
                  </CardText>
                </CardBody>
                <CardFooter className="text-muted">
                  <footer style={{ textAlign: "center", }}>
                    <ul className="faico clear"></ul>
                  </footer>
                </CardFooter>
              </Card>
              <Card className="teamcard">
                <CardHeader>
                  <CardTitle style={{ fontSize: "25px", textAlign: "center" }}>Jeremy Smith</CardTitle>
                </CardHeader>
                <img id="teamimage" style={{ height: "250px", width: "250px", marginLeft: "auto", marginRight: "auto", marginTop: "5px" }} src={Jeremy} alt="Card image cap" />
                <CardBody>
                  <CardText>
                    Jeremy Smith is a Senior Lecturer in food innovation at Massey University. With a diverse background, built upon qualifications in both science (PhD) and business (MBA) he has lead a multi-million dollar project involving a consortium of 7 companies representing a broad cross section of product categories within the food industry. Jeremy leads the postgraduate innovative product design and strategic product development courses across the Palmerston North, Albany and Singapore campuses.
                  </CardText>
                </CardBody>
                <CardFooter className="text-muted">
                  <footer style={{ textAlign: "center", }}>
                    <ul className="faico clear"></ul>
                  </footer>
                </CardFooter>
              </Card>
            </Row>
          </CardDeck>
        </div>
        <div className="clear" />
      </main>
    </div>
  );
}

export default ProjectTeam;
