//Import react
import React, { Component } from "react";

//Import banner
//import Banner from "../images/flag-background.png";
// import Background Video
// import BackVideo from  "../images/fern-1024.mp4";
// import BackVideo2 from  "../images/fern-1024.webm";

import BackVideo3 from "../images/3.8-fern.mp4";
import BackVideo4 from "../images/3.8-fern.webm";


//Import components
//import Menu from "./Menu.js";
import Menu from "./NewMenu.js";

import '../styles/videobackground.css';
import '../styles/layout.css';



/**
 * Component Header
 *  - Header of the Dashboard. Includes the menu component
 * 
 *  Props:
 *    - ChangeViewButtonClick : function passed from dashboard, handles swapping of nationa/regional views on click of buttons
 * 
 *    - PredictionData : the jsondata, used in this component to get the data of the last prediction and the last inputs date
 * 
 *  State:
 *    - repeatCycleTimeoutCode, cycleButtonCodes : store timeout codes for the button cycling. 
 * 
 *    - Timeout events need to be cancelled otherwise website will crash when navigating of the dashboard
 * 
 *    - lastButtonCycled : used to check the last button which was animated 
 * 
 */

//Animation interval for button cycling

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      repeatCycleTimeoutCode: null,
      cycleButtonTimeoutCodes: null,
      lastButtonCycled: null
    };

    this.SetButton = this.SetButton.bind(this);
  }

  //Start button cycling on component mount
  componentDidMount() {
  }

  //Clear timeouts on component unmount otherwise app will crash when navigating away from dashboard
  componentWillUnmount() {
    for (let i = 0; i < 3; i++) {
      //clearTimeout(this.state.cycleButtonTimeoutCodes[i]);
    }
    //clearTimeout(this.state.repeatCycleTimeoutCode);
  }



  //Called inside the for loop in CycleButtons
  SetButton(button) {
    //Remove the animation from the last button that was cycled
    if (this.state.lastButtonCycled != null) {
      this.state.lastButtonCycled.classList.remove("animationMenu");
    }

    //Add the animation to the next button and save to state
    button.classList.add("animationMenu");
    this.setState({ lastButtonCycled: button });
  }

  render() {
    return (
      <div>
        <div className="bgded overlay">
          {/* Menu, call the menu component with the ChangeViewButtonClick function recieved from Dashbaord */}
          {/* TODO:  Check original! - Changed menu to menu2 created by Boostrap */}
          <Menu ChangeViewButtonClick={this.props.ChangeViewButtonClick} IndicatorData={this.props.IndicatorData} />
        </div>

        <header className="v-header container">
          <div className="fullscreen-video-wrap">
            <video id="trial" playsInline className='VideoTag' autoPlay loop muted>
              <source src={BackVideo3} type='video/mp4' />
              {/*<source src={BackVideo4} type='video/webm' />*/}
            </video>
          </div>
          <div className="header-overlay"></div>
          <div className="header-content">
            <article>
              <h1 className="heading">New Zealand Shared Prosperity Index</h1>
              <h2 className="heading">Annual tracking of national prosperity across multiple dimensions </h2>
            </article>
          </div>
        </header>

        {/* Start of Overview */}
        <div className="wrapper row3">
          <main className="hoc container clear">
            <article className="one_half first">
              <h4 className="font-x2 font-x3">
                Shared Prosperity in New Zealand
              </h4>
              <p>
                Global prosperity has been rising for more a decade, and New Zealand has benefitted from this trend, ranking the <b>second most prosperous country in the world in 2018*</b>. However, there are signs in many countries, including New Zealand, that as overall prosperity increases, there are some people being left further and further behind. This has prompted increasing public debate about whether the current economic model, shared by many of the wealthier countries in the world, still delivers sufficient benefits for everyone, or whether it is advantaging a sector of society at the expense of the rest.
              </p>
              <p>
                By almost all measures, New Zealand is a prosperous country, with solid GDP growth, healthy surpluses, low unemployment and crown debt. Yet on the flipside it seems the gap between the haves and the have-nots is growing, with income inequality, child poverty and homelessness rising. Those most affected are low-income Māori and Pacific Peoples communities and certain sectors of the community like the elderly and children. It is not something that sits well with many Kiwis who have prided themselves as belonging to one of the most egalitarian nations in the world. In fact, New Zealanders have consistently rated inequality as the single biggest issue facing the country since 2014, with more than 80 per cent of the country saying they are concerned or very concerned about income and wealth imbalances.
              </p>
              <p>
                Declining shared prosperity has been linked at a macro level to trends like globalization and advancements in technology, both of which tend to disproportionately affect the poor. At a domestic level the origins for New Zealand go back to the economic reforms of the 1980s when free-market policies were introduced in an effort to modernise New Zealand's heavily regulated economy. The transition was hard and New Zealand battled through soaring unemployment (from restructuring the public sector and the effects of an open economy) and inflation.
              </p>
              <p>
                Tax reforms in the 1980s reduced the top marginal income tax rate from 66 percent to 33 percent and a consumption tax (GST) was introduced in 1986. Welfare reforms were also introduced during this period to encourage people off welfare and into work. Social welfare benefits were cut in the early 1990s along with the messaging that welfare was to give a hand up not a hand out. The period from 1998 to 2006 was a period of strong growth in New Zealand, but also a time of increasing inequality. The gap between high and low-income earners grew strongly over this time and continued throughout the 2000s. By 2013, the disposable income of high-income households was more than two and a half times more than that of low-income households.  This was partially offset for middle and low-income earners with the introduction of Working for Families in 2014, but the country has never regained the levels of equality seen prior to the late-1980s. In recent years, rising house costs have compounded this effect, creating a breeding ground for unequal distribution of prosperity.
              </p>
              <p>
                *according to the Legatum Prosperity Index 2018
              </p>
              <footer>
                <ul className="nospace inline pushright"></ul>
              </footer>
            </article>

            <article className="one_half last">

              <h4 className="font-x2 font-x3">Background to the Shared Prosperity Index</h4>
              {/* TODO: Image removed! Ask about the image */}
              {/* <img
                  className="btmspace-10"
                  src={IntroductionImage}
                  alt=""
                  style={{ maxWidth: "50%", maxHeight: "50%" }}
                /> */}
              <p>
                The Shared Prosperity Index was developed to identify the level of shared prosperity in New Zealand at any given point in time. The central tenant of the Shared Prosperity Index is that <b>a prosperous society is one where everyone has the opportunity to take part and share in the generated wealth and benefits of a modern economy.</b>               </p>
              <p>
                The Index takes a broad view of what prosperity means in New Zealand. Prosperity is not limited to material income and wealth but includes access to healthcare and education, the availability of affordable housing, and employment opportunities that enable its citizens to flourish and realise their ambitions. New Zealanders feel less prosperous when they are vulnerable to high rates of violent crime and expanding prison populations.              </p>
              <p>
                In an economy that fairly shares its prosperity people believe there is some possibility of <b>social mobility</b>, and that they will be able <b>to realise their potential</b>. If they don’t believe this, and where they are surrounded by extreme inequalities, they are more likely to feel discontented and resentful.               </p>
              <p>
                The aim of the Shared Prosperity Index is to shine a light on the level of shared prosperity in New Zealand and in doing so to encourage debate, and further study, about the subject.               </p>
              <p>
                The Shared Prosperity Index scores the country based on <b>Eight Dimensions</b>: Income & Wealth, Socio-economic Wellbeing, Health, Education, Employment, Housing, Safety & Security and General Equality. The score is a measure of the country’s performance based on that particular dimension. The average of all the dimensions gives an <b>overall shared prosperity score.</b>
              </p>
            </article>
          </main>
        </div>
        {/* End of Overview */}
      </div>
    );
  }
}

export default Header;


