import React, { Component } from "react";
//Import components
import Footer from "./components/Footer.js";
//import Menu from "./components/Menu.js";
import Menu2 from "./components/NewMenu.js";
import { MDBRow, MDBCol, MDBIcon } from "mdbreact";
import Popup from 'reactjs-popup';
import Modal from 'react-responsive-modal';

import IndicatorData from './indicators.json';

//Import main stylesheet
import "./styles/popups.css";

const Card = ({ title, info }) => (
  <div className="card">
    <div className="header"><b>{title}</b></div>
    <div className="content">
      {/*info */}
      <div dangerouslySetInnerHTML={{ __html: info }} />
    </div>
  </div>
);

const CreateToolTip = ({ title, info }) => (
  <Popup
    trigger={<MDBIcon
      icon="info-circle"
      size="1x"
    />}
    position="top center"
    on="hover"
  >
    <Card title={title} info={info} />
  </Popup>
);


/**
 * Functional Component About
 *  - Display further information on project aims, partners, project team and the full disclaimer
 * 
 * @param {*} props 
 */

class CompleteDimensionGraphs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      graphTitle: "",
      idx: 0,
      previewImage: null,
      myMap: new Map()
    };
    //this.currentIndex = null;
    //this.myMap = new Map();
  }

  ChangeGraph(title, index) {
    //Check that this is a new graph
    if (title !== this.state.graphTitle) {
      this.setState({ graphTitle: title, idx: index });
    }
  }

  /** on Component Mount, create a string array of Plotly Url's and save to state
   *  use these url's to retrieve preview images of graphs from /public folder
   */
  componentWillMount() {
    /*
    if (this.props.location.data == null) {
      this.props.location.data = 'SafetyAndSecurity';
    }
    */
    let i = 0;
    //var myMap = new Map();
    for (var k in IndicatorData[this.props.location.data]['Indicators']) {
      this.state.myMap.set(i, k)
      i = i + 1;
    }
    this.setState({ graphTitle: this.state.myMap.get(0) });


  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }


  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };




  RenderIndicator(graphIndex, graphName) {
    /**
     * Render a single row in the table
     * 
     * Each row onClick changes the graph above this component using function passed through props
     * 
     * Each image in the row has a hover event which calls the DisplayHoverGraph function
     */
    let data = this.props.location.data;

    let urlParametrs = ".embed?link=false&modebar=false&autosize=True&width=100%";
    let jpegParameters = ".jpeg?link=false&modebar=false&autosize=True&width=350&height=170";
    //let plotlyPath = this.props.IndicatorData[data]['Indicators'][this.props.IndicatorData[data]['Website']['IndicatorGraphs'][graphIndex]]['plotly_file_path'];
    const { open } = this.state;
    let idx = this.state.idx;

    console.log('RenderIndicator:' + data);
    console.log('RenderIndicator:' + graphIndex);
    console.log('RenderIndicator:' + graphName);
    console.log('================================================================');

    let plotlyFileName = IndicatorData[data]['Indicators'][this.state.graphTitle]['plotly_file_path'];


    //plotlyFileName = this.props.IndicatorData[data]['Indicators'][this.props.IndicatorData[data]['Website']['IndicatorGraphs'][this.state.idx]]['plotly_file_path'] + urlParametrs;

    let position = "";
    if ((graphIndex) % 3 == 0) {
      position = "first";
    }

    return (
      <li className={"one_third " + position}>
        <div style={{ alignItems: "left", alignContent: "left" }}>

          <MDBRow>
            <MDBCol md="9"><h5 className="font-xs" style={{ color: "#474747" }}> {IndicatorData[data]['Indicators'][graphName]["Indicator name (alternative)"]} </h5>
            </MDBCol>


            <MDBCol md="1">
              <a onClick={() => this.ChangeGraph(graphName, graphIndex)}
                style={{ display: "block", color: "black" }}
                id={graphName}
              >
                <MDBIcon
                  icon="expand"
                  size="1x"
                  className="pink-text "
                  onClick={this.onOpenModal}

                ></MDBIcon>
              </a>
              <Modal className="styles_modal__gNwvD" onClose={this.onCloseModal} open={open} center >

                <h2> {IndicatorData[data]['Indicators'][this.state.graphTitle]['Indicator name (alternative)']} </h2>
                <a id="graph" />
                <iframe className="iframe" frameborder="0" scrolling="yes" src={plotlyFileName + urlParametrs}></iframe>
              </Modal>
            </MDBCol>


            <MDBCol md="1">
              <CreateToolTip title={IndicatorData[data]['Indicators'][graphName]["Indicator name (alternative)"]}
                info={IndicatorData[data]['Indicators'][graphName]["Description"] + '<br>' +
                  "<b>Rationale:</b> " + IndicatorData[data]['Indicators'][graphName]['Rationale'] + '<br>' +
                  "<b>Weight:</b> " + IndicatorData[data]['Indicators'][graphName]['Weight'] + '<br>' +
                  "<b>Data Source:</b> " + IndicatorData[data]['Indicators'][graphName]['Source'] + '<br>' +
                  "<b>No. of values:</b> " + IndicatorData[data]['Indicators'][graphName]['No. of values'] + '<br>' +
                  "<b>Year of last recorded value:</b> " + IndicatorData[data]['Indicators'][graphName]['Year of last recorded value'] + '<br>' +
                  "<b>Min value:</b> " + IndicatorData[data]['Indicators'][graphName]['Min value'] + '<br>' +
                  "<b>Max value:</b> " + IndicatorData[data]['Indicators'][graphName]['Max value']
                } />
            </MDBCol>
          </MDBRow>

          <section
            style={{ paddingTop: "0px", paddingLeft: "0px", alignItems: "left" }}>
            <a id="graph" />
            <iframe className="iframe" frameborder="0" scrolling="no"
              src={IndicatorData[data]['Indicators'][graphName]['plotly_file_path'] + jpegParameters} ></iframe>
          </section>
        </div>
      </li>
    );
  }




  render() {

    const { myMap } = this.state;
    console.log(myMap);
    for (const k of myMap.keys()) {
      console.log(k)
      console.log(myMap.get(k))
    }

    console.log('================================================================');

    let data = this.props.location.data;

    const { open } = this.state;

    var indicatorNames = [];
    for (var k in IndicatorData[data].Indicators) indicatorNames.push(k);

    let indicatorGraphSections = [];
    //console.log(this.props['Website']['IndicatorGraphs'])
    //console.log(this.props['Website']['IndicatorGraphs'])
    console.log("printing indicator names..");
    for (let i = 0; i < indicatorNames.length; i++) {
      console.log('myMap: ' + myMap.get(i));
      console.log('indicatorNames: ' + indicatorNames[i]);
      indicatorGraphSections.push(
        //IndicatorData[data]['Indicators'][ indicatorNames[i] ]['var_code']
        this.RenderIndicator(i, indicatorNames[i])

      );
    }
    console.log('indicatorGraphSections')
    for (let i = 0; i < indicatorGraphSections.length; i++) {
      //console.log(indicatorGraphSections[i])
    }

    return (

      <div>
        <div className="bgded overlay">
          {/* Menu Component */}
          <Menu2 ChangeViewButtonClick={this.props.ChangeViewButtonClick} />
        </div>

        {/* Start of Project Aims */}
        <a id="aims" />
        <main className="hoc container clear">
          <h2 className="font-xs font-x3" style={{ color: "#474747" }}>{IndicatorData[data]['OfficialTitle']} Indicators</h2>
          <ColoredLine spec={{ color: "#474747", hight: "3" }} />
        </main>
        <main className="hoc container clear">
          <section>
            <ul>
              {indicatorGraphSections}
            </ul>
          </section>
        </main>
        {/* Footer Component */}
        <Footer />
      </div>
    );
  }
}

const ColoredLine = ({ spec }) => (
  <hr
    style={{
      color: spec.color,
      backgroundColor: spec.color,
      height: parseInt(spec.hight)
    }}
  />
);
export default CompleteDimensionGraphs;

