//Import react
import React, { Component } from "react";

//Import moment to get current date
import moment from "moment";

/**
 * Component Graph
 *  - Component to show the main graph. Displayed below the regional/national view on the main dashboard
 *  - Used in GraphHover.js if showPreview prop is displayed
 *  - Also used in the "InDepthView" page to display a graph in full
 * 
 *  Props:
 *    - plotlyConnection: tells component if plotly server is running. If not running don't display graphs
 * 
 *    - graph: the index of the plotly graph to be generated
 * 
 *    - preview : path of the preview image to be shown before the plotly graph loads
 * 
 *    - title : title of the graph to be generated
 * 
 *    - isMobileDevice: tells component if app is being viewed on a mobile device
 * 
 *  State:
 *    - showChart: the display property of the plotly graph. Set to "none" initially when plotly graph is loading,
 *                 then set to "inline" once the iframe has loaded
 * 
 *    - showPreview: determines if the preview image should be shown while the graph is loading
 */


class Graph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showChart: "none",
      showPreview: true,
    };
  }

  /**
   * Lifecycle function componentWillReceiveProps
   * 
   * if component props have updated, set showChart to "none" and enable preview image
   * This is done so that when the other props which store the graph data change, the preview image will reload as well
   * 
   * Otherwise when the component displays the new graph, there will be a time delay of a few seconds before the new graph shows
   * 
   * @param {*} nextProps the new props recieved 
   */

  componentWillReceiveProps(nextProps) {
    //Check that the old version of the preview prop and the new version of the preview prop are different
    if (this.props.preview !== nextProps.preview) {
      this.setState({ showChart: "none", showPreview: true });
    }
  }

  render() {
    // local vars to store the preview image and the iframe html
    let preview, iframe = null;

    //get current date for use in the graph title
    var time = moment().format("MMMM Do YYYY");

    //Set plotly url, graph options can be changed at the end of the url
    var GraphSrc = "https://plot.ly/~tsusnjak/" + this.props.graphIndex + ".embed?link=false&modebar=false&autosize=True";

    //Alternate version to be used once data is changed to use full Url's instead of numbers
    // var GraphSrc = this.props.graphIndex;



    /**
     * Check if plotly server is working and that website is not running on a mobile device
     * 
     * Otherwise don't display plotly iframe, use preview image instead
     * 
     * Disable plotly graph on mobile device because graphs do not load correctly (screen size too small for graphs)
     */
    
    ///* comment this section out to deactivate plotly and use jpeg only
    if (this.props.plotlyConnection && !this.props.isMobileDevice) {
      iframe = (
        //Set style of Iframe
        <iframe
          className = "iframe"
          style = {{ display: this.state.showChart }}
          //Show chart after iframe has loaded. Disable show of preview image
          onLoad={() => {
            this.setState({ showChart: "inline", showPreview: false });
          }}
          title="test_chart"
          //Set src to plotly Url
          src={GraphSrc}
        />
      );
    } //*/
    
    // uncomment this line in order to deactivate plotly and use jpeg only
    //this.state.showPreview = 1 

    //If preview enabled, render component
    if (this.state.showPreview) {
      preview = (
        <img className = "previewImage" src={this.props.preview} alt="preview of graph"
        />
      );
    }

    return (
      <div style={{ width: "100%", height: "80%" ,position: "relative", textAlign: "center"}}>
        <h3 id = "graphheading">{this.props.title}</h3>
        <h3 style = {{fontSize: "16px"}}> Generated on {time} </h3>
        {preview}
        {iframe}
      </div>
    );
  }
}
export default Graph;
