import React, { Component } from 'react';
import {
  Collapse, Navbar, NavbarToggler, NavbarBrand, NavLink
} from 'reactstrap';
import '../styles/Menu.css';

import IndicatorData from '../indicators.json';

//Import HashLink for anchor tags link to sections on another page correctly
import { HashLink as Link } from 'react-router-hash-link';

//Import logos
import MasseyLogo from "../images/masseylogo.jpg";
import KEHLogo from "../images/kehlogo.png";
import SPILogo from "../images/SPI_Blue.jpg";



class Menu extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {

    return (

      <Navbar light expand="md">
        <div className="navLogo">
          <NavbarBrand className="row" href="https://www.massey.ac.nz">

            <img id="masseylogo" src={MasseyLogo} alt="Massey University Logo" />

          </NavbarBrand>
          <NavbarBrand className="row" href="http://www.massey.ac.nz/massey/initiatives/enterprise/auckland-knowledge-exchange/auckland-knowledge-exchange_home.cfm">

            <img id="kehlogo" src={KEHLogo} alt="Knowledge Exchange Hub Logo" />

          </NavbarBrand>
        </div>

        <NavbarBrand href="/">

          <img id="gdplivelogo" src={SPILogo} alt="Home" />

        </NavbarBrand>



        <NavbarToggler onClick={this.toggle} ></NavbarToggler>
        <Collapse isOpen={this.state.isOpen} navbar>



          <nav className="nav">
            <ul className="nav__menu">
              <li className="nav__menu-item">
                <NavLink to="#" tag={Link}
                // Swap dashboard view using function recieved as a prop onClick={evt => this.props.ChangeViewButtonClick("Regional")}
                >Index Dimensions</NavLink>
                <Submenu_Dimensions IndicatorData={this.props.IndicatorData} />
              </li>
              {/*<li
              className="nav__menu-item">
             <NavLink tag= {Link} to="/Dashboard#report">Commentary</NavLink>
             <Submenu_Commentary />
            </li>*/}

              <li
                className="nav__menu-item">
                <NavLink>Publications</NavLink>
                <Submenu_TechnicalDetails />
              </li>
              {/*<li
              className="nav__menu-item">
              <NavLink to="/Dashboard#partners" tag= {Link}>Data Sources</NavLink>
             
            </li>*/}
              <li className="nav__menu-item">
                <NavLink to="/About#aims" tag={Link}>About</NavLink>
                <Submenu_About />
              </li>

              <li className="nav__menu-item">
                <NavLink>Other Projects</NavLink>
                <Submenu_OtherProjects />
              </li>

            </ul>
          </nav>

        </Collapse>


      </Navbar>
    );
  }
}



class Submenu_Dimensions extends Component {
  constructor(props) {
    super(props);
  }


  RenderInidcatorLink(dimansionName) {

    return (
      <li className="nav__submenu-item " key={dimansionName}>
        <NavLink to={"/Dashboard#" + dimansionName} tag={Link}
        >{dimansionName}</NavLink>
      </li>

    );

  }

  render() {

    let indData = this.props.IndicatorData;
    if (indData == null) {
      indData = IndicatorData;
    }

    //console.log("MENU");
    //console.log(this.props.IndicatorData);
    //console.log(IndicatorData);
    //console.log(indData);

    let dimensionLinks = [];
    //console.log("UYGVGVGVGVGVGVGVGVGVGVGVGVGVGVGVGVGVGVGVGVGVGVGVGVGVGVGVGVGVGVGVGVGVGVGVGVGVGVGVGVGVGVGVGVGV");
    //console.log(this.props.IndicatorData);
    for (var i in indData) {
      if (indData[i].hasOwnProperty('OfficialTitle')) {
        dimensionLinks.push(
          this.RenderInidcatorLink(indData[i]['OfficialTitle'])
        );
      }
    }
    //console.log(dimensionLinks);
    //console.log("anyhing?");

    return (
      <ul className="nav__submenu">
        {dimensionLinks}
        {/*<li className="nav__submenu-item ">
                   <NavLink to="/Dashboard#Income and Wealth" tag= {Link} 
                // Swap dashboard view using function recieved as a prop
                 >Income and Wealth</NavLink> 
            </li>

          <li className="nav__submenu-item ">
             <NavLink to="/Dashboard#Employment" tag= {Link}
                // Swap dashboard view using function recieved as a prop
                >Employment</NavLink>
          </li>
          <li className="nav__submenu-item ">
          <NavLink to="/Dashboard#industry-forecast" tag= {Link}>Industry Sector Predictions</NavLink>
      </li>*/}
      </ul>
    );
  }
}
class Submenu_OtherProjects extends React.Component {
  render() {
    return (
      <ul className="nav__submenu">

        <li className="nav__submenu-item ">
          <a href="http://www.gdplive.net">GDPLive</a>
        </li>

        {/*<li className="nav__submenu-item ">
          <a href="/TechnicalDetails#limitations">Limitations</a> 
          </li>*/}
      </ul>
    )
  }
}


class Submenu_TechnicalDetails extends React.Component {
  render() {
    return (
      <ul className="nav__submenu">

        <li className="nav__submenu-item ">
          <NavLink to="/MainArticle" tag={Link}>Detailed Article</NavLink>
        </li>

        {/*<li className="nav__submenu-item ">
          <a href="/TechnicalDetails#limitations">Limitations</a> 
          </li>*/}
      </ul>
    )
  }
}


class Submenu_Commentary extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ul className="nav__submenu">
        <li className="nav__submenu-item ">
          <NavLink to="/GDPBackground" tag={Link}>GDP Background</NavLink>
        </li>
      </ul>
    )
  }
}

class Submenu_About extends React.Component {
  render() {
    return (
      <ul className="nav__submenu">

        <li className="nav__submenu-item ">
          <NavLink to="/About#KnowledgeExchangeHub" tag={Link}>Knowledge Exchange Hub</NavLink>
        </li>

        <li className="nav__submenu-item ">
          <NavLink to="/About#SharedProsperityIndexTeam" tag={Link}>Team</NavLink>
        </li>

        <li className="nav__submenu-item ">
          <NavLink to="/About#Disclaimer" tag={Link}>Disclaimer</NavLink>
        </li>

        <li className="nav__submenu-item ">
          <NavLink to="/About#TermsOfUse" tag={Link}>Terms of Use</NavLink>
        </li>

        {/*<li className="nav__submenu-item ">
          <a href="/TechnicalDetails#limitations">Limitations</a> 
          </li>*/}
      </ul>
    )
  }
}


export default Menu;

