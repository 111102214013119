import React, { Component } from "react";
//Import components
import Footer from "./components/Footer.js";
//import Menu from "./components/Menu.js";
import Menu2 from "./components/NewMenu.js";
import { MDBRow, MDBCol, MDBIcon } from "mdbreact";
import Popup from 'reactjs-popup';
import Modal from 'react-responsive-modal';
import ProjectTeam from "./components/ProjectTeam.js";

import IndicatorData from './indicators.json';

//Import main stylesheet
import "./styles/popups.css";

const Card = ({ title, info }) => (
  <div className="card">
    <div className="header">{title}</div>
    <div className="content">
      {info}
    </div>
  </div>
);

const CreateToolTip = ({ title, info }) => (
  <Popup
    trigger={<MDBIcon
      icon="info-circle"
      size="1x"
    />}
    position="top center"
    on="hover"
  >
    <Card title={title} info={info} />
  </Popup>
);


/**
 * Functional Component About
 *  - Display further information on project aims, partners, project team and the full disclaimer
 * 
 * @param {*} props 
 */

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      graphTitle: "",
      idx: 1,
      previewImage: null,
    };
    //this.currentIndex = null;
    //this.myMap = new Map();
  }

  ChangeGraph(title, index, image) {
    //Check that this is a new graph
    if (title !== this.state.graphTitle) {
      this.setState({ graphTitle: title, idx: index, previewImage: image });
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }


  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };


  render() {

    return (

      <div>
        <div className="bgded overlay">
          {/* Menu Component */}
          <Menu2 ChangeViewButtonClick={this.props.ChangeViewButtonClick} />
        </div>

        {/* Start of Project Aims */}
        <a id="KnowledgeExchangeHub" />
        <div className="wrapper row3">
          <main className="hoc container clear" style={{ paddingTop: "30px" }}>
            <article className="one_third first" style={{ width: "100%" }}>
              <h4 className="font-x2 font-x3">The Knowledge Exchange Hub</h4>

              <p>
                The Knowledge Exchange Hub is a world-class centre for curiosity-driven research in the field of social citizenship and big data analytics.
              </p><p>
                Bridging traditional humanities/social science and science practices and modes of thought, the Knowledge Exchange Hub aims to be a leading centre of curiosity-driven research, bringing together a dedicated team of researchers to identify and work on critical political, ethical and intellectual issues that face New Zealand and the world today.
              </p><p>
                For more information about the Shared Prosperity Index or to speak to one of the Knowledge Exchange Hub experts, please email us.
              </p>
            </article>
            <div className="clear" />
          </main>
        </div>



        {/* Start of Team */}
        <a id="SharedProsperityIndexTeam" />
        <div className="wrapper row3">
          <main className="hoc container clear" style={{ paddingTop: "30px" }}>
            <article className="one_third first" style={{ width: "100%" }}>
              <h4 className="font-x2 font-x3">Shared Prosperity Index Team</h4>
            </article>
            <div className="clear" />
            <p>
              The research team has complementary research skills covering areas of philosophy, ethics, theoretical economics, mathematical modelling, data analytics and public policy. Together the team is well qualified to address all aspects of Shared Prosperity and consists of:
            </p>

            {/*<p>
 Christoph Schumacher, Director of the Knowledge Exchange Hub, is an expert in theoretical and applied economics, mathematical modelling and welfare economics.
 </p><p>
 Teo Susnjak is a computer scientist with expertise in data science and machine learning. 
 </p><p>
 John Matthewson is an expert in medicine, philosophy and social justice.
 </p><p>
 Paul Geertsema with his expertise in mathematical modelling, machine learning and finance.
 </p><p>
 Ajmol Ali is en expert in sports nutrition, sports science, physical education and sports science.
 </p><p>
 Rachel Owens is an expert in data science and statistics.
 </p>   */}
          </main>
        </div>

        {/*Start of Project Team */}
        <a id="team" />
        <ProjectTeam />
        {/*End of Project Team */}

        {/* Start of Team */}
        <a id="Disclaimer" />
        <div className="wrapper row3">
          <main className="hoc container clear" style={{ paddingTop: "30px" }}>
            <article className="one_third first" style={{ width: "100%" }}>
              <h4 className="font-x2 font-x3">Disclaimer</h4>
            </article>
            <div className="clear" />
            <p>
              This website is a product of ongoing academic research. It provides general information and estimates on the level of shared prosperity in New Zealand over time. Our goal is to keep this information as timely and accurate as possible given the availability of data sources. If errors are brought to our attention, we will try to correct them. We may add, change, improve, or update the information on the website without notice. The analyses, information, views and opinions are solely those of the researchers involved in this project and do not reflect those of its employer, or associated institutions and companies.
            </p><p>
              Whilst reasonable care has been taken in the preparation of this index and associated analysis and commentary, no responsibility can be taken for any error or omission contained herein.
            </p><p>
              The researchers and their employer shall not be liable for any loss or damage sustained by any person relying on information obtained on this website or in connection with this research.
            </p>
          </main>
        </div>


        {/* Start of Team */}
        <a id="TermsOfUse" />
        <div className="wrapper row3">
          <main className="hoc container clear" style={{ paddingTop: "30px" }}>
            <article className="one_third first" style={{ width: "100%" }}>
              <h4 className="font-x2 font-x3">Terms of Use</h4>
            </article>
            <div className="clear" />
            <p>
              We encourage the sharing of the data and analysis contained in this website. When doing so, please reference Shared Prosperity Index (SPI) as your source and, where possible, link back to this website. Our preferred format for citations is: Source: Shared Prosperity Index NZ™ (www.sharedprosperity.co.nz)
            </p><p>
              Whilst the Knowledge Exchange Hub encourages you to use and share the data and analysis from the SPI, in so doing you agree that neither you nor any third party, shall use SPI for commercial or revenue-generating purposes.
            </p><p>
              For any enquiries about the distribution and usage of any material from the SPI, please contact us.
            </p>
          </main>
        </div>


        {/* Start of GDP Index */}
        {/* Footer Component */}
        <Footer />
      </div>
    );
  }
}

const ColoredLine = ({ spec }) => (
  <hr
    style={{
      color: spec.color,
      backgroundColor: spec.color,
      height: parseInt(spec.hight)
    }}
  />
);
export default About;

