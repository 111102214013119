//Import React
import React, { Component, useRef } from "react";
import { Card, CardImg, CardBody, CardTitle, CardSubtitle, Col } from "reactstrap";
import { MDBRow, MDBCol, MDBIcon } from "mdbreact";
import ScrollUpButton from "react-scroll-up-button";
import { FaInfoCircle } from 'react-icons/fa';


//Import main stylesheet
import "../styles/layout.css";

//Styles for toggle component
import "../styles/toggle.css";

//Import components
import Footer from "./Footer.js";
import Header from "./Header.js";

import DimensionTemplate from "./DimensionTemplate.js"

import Popup from 'reactjs-popup';
import Modal from 'react-responsive-modal';

import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
} from 'react-accessible-accordion';
import "../styles/CustomAccordion.css";

//Import external libraries
import axios from "axios";

//Import images
import AlpsBanner from "../images/new-zealand-1.jpg";

//Import domain id
import config from "../config.json";

//Import main stylesheet
import "../styles/popups.css";

var domain = config["domain"];
var moment = require("moment");

//Set colours for SVG map. Pass these constants to Regional and NationalView Components
const RedLightFill = "#E57373";
const RedMediumFill = "#F44336";
const RedDarkFill = "#C62828";
const BlueLightFill = "#BBDEFB";
const BlueMediumFill = "#42A5F5";
const BlueDarkFill = "#1565C0";

//Get plotly image from public folder
const GDPPreview = "https://" + domain + "/public/Inputs/GDP.png";

const urlForFeedToJson = feed => `https://api.rss2json.com/v1/api.json?rss_url=${feed}`;


const CreateToolTip = ({ title, info }) => {
  return (
    < Popup
      trigger={<button className="button" style={{ background: 'none', border: 'none' }}>
        <FaInfoCircle size="1.2em" />
      </button>}
      position="top center"
    >
      <div>
        <h3>{title}</h3>
        <p>{info}</p>
      </div>
    </Popup >
  );
};


/**
 *  Dashboard Component
 *   - The main page of the app, all components in the /components folder are embedded in this component
 *
 *  Props:
 *      - PredictionData: The json data containing the predictions passed in from the App component
 * 
 *      - PlotlyIndex: json data containing the plotly indexes used to render the appropriate graphs from the plotly server. Passed into subcomponents
 *
 *  State:
 *      - view: The current dashboard view (a string). Toggles between national and regional
 * 
 *      - graphTitle : The current title of the graph displayed on the dashboard. Changes based on actions of other components
 * 
 *      - plotlyIndex: the number of the plotly graph currently being displayed, used to tell the iframe which graph to render
 * 
 *      - previewImage : the preview image of the graph currently being displayed
 * 
 *      - graphHeight: The height of the graph component. Changes on mobile devices. Default value is for 1080p screens
 * 
 *      - dashboardHeight : The height of the main dashboard (the national/regional view). Changes on mobile devices. Default value is for 1080p screens
 * 
 *      - SVGViewport : String specifying the size of the map SVG. Changes based on device. Default value is for 1080p screens
 * 
 *      - isMobileDevice : passed to other components to change their behaviour if viewing website on a mobile device
 * 
 *      - plotlyConnection : true/false depending on if plotly server is running. If not running, don't display Plotly Iframes, use images instead
 * 
 * 
 */

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      view: "National",
      graphHeight: 700,
      dashboardHeight: 900,
      SVGViewport: "0 0 1600 1600",
      isMobileDevice: false,
      plotlyConnection: false,
      mediumPosts: [],

      graphTitle: "",
      idx: 0,
      previewImage: null,

    };
    this.ChangeViewButtonClick = this.ChangeViewButtonClick.bind(this);
    this.ChangeGraph = this.ChangeGraph.bind(this);
    this.ChangeViewToggle = this.ChangeViewToggle.bind(this);
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };


  componentWillMount() {


    //this.CheckPlotlyConnection();
    //this.VerifyPlotlyConnection();

    //Check screen width and set state variables accordingly

    //Laptops
    if (window.screen.width > 1200 && window.screen.width < 1500) {
      this.setState({ dashboardHeight: "800px" });
    } else if (window.screen.width > 1000 && window.screen.width < 1200) {
      //Large Tablets
      this.setState({ dashboardHeight: "800px" });
    } else if (window.screen.width > 800 && window.screen.width < 1000) {
      //Small Tablets
      this.setState({ SVGViewport: "0 0 1800 1800", dashboardHeight: "800px" });
    } else if (window.screen.width > 700 && window.screen.width < 800) {
      this.setState({
        SVGViewport: "0 0 1500 1500",
        graphHeight: "500px",
        dashboardHeight: "1000px",
        isMobileDevice: true
      });
    } else if (window.screen.width > 450 && window.screen.width < 700) {
      this.setState({
        SVGViewport: "0 0 1500 1500",
        graphHeight: "500px",
        dashboardHeight: "900px",
        isMobileDevice: true
      });
    }
    else if (window.screen.width < 450) {
      //Mobile Phones, reduce height of graph and dashboard on hover
      this.setState({
        SVGViewport: "0 0 1100 1100",
        graphHeight: "300px",
        dashboardHeight: "850px",
        isMobileDevice: true
      });
    }
  }

  /** Use axios to check if plotly server is online. Update plotlyConnection state */
  CheckPlotlyConnection() {
    let connection = true;
    axios({
      method: "get",
      //url: "https://plot.ly/~tsusnjak/818/prediction-095-ci-099-ci-095-ci-099-ci-interpolated-gdp-trainingdata-actual-gdp/",
      //url: "https://sdfsfsd.com/",
      url: "https://plot.ly/",
      timeout: 3000,
      responseType: "text"
    }).then(function (response) {
      if (response.status !== 200) {
        connection = false;
      }
    });
    this.setState({ plotlyConnection: connection });
  }

  componentDidMount() {
    //window.mediumWidget();
    fetch(urlForFeedToJson(`https://medium.com/feed/@sharedprosperitynz`))
      .then(response => {
        if (!response.ok) {
          throw Error("Network request failed")
        }
        return response
      })
      .then(data => data.json())
      .then(data => {
        const dataItems = data.items
        this.setState({
          mediumPosts: dataItems,
          isLoading: true,
          test: "pranilesh"
        })
      }, () => {
        this.setState({
          requestFailed: true,
          mediumPosts: []
        })
      })
  }

  /**
   * Function VeifyPlotlyConnection
   * 
   * Second version of checking Plotly responsiveness
   * 
   */
  MakePlotlyRequest = async () => {
    try {
      let connection = true;
      return await axios({
        method: "get",
        //url: "https://plot.ly/~tsusnjak/818.embed?link=false&modebar=false&autosize=True",
        //url: "https://sdfsfsd.com/",
        url: "https://plot.ly/",
        timeout: 3000,
        responseType: "text"
      }).then(function (response) {
        if (response.status !== 200) {
          connection = false;
        }
        return connection;
      });
    } catch (error) {
      console.error(error)
    }
  }

  VerifyPlotlyConnection = async () => {
    const httpStatusCode = await this.MakePlotlyRequest()
    this.setState({ plotlyConnection: httpStatusCode });
  }

  /**
   * Function SetColour
   * 
   * Check a single GDP value and return a different colour depending on what range it's in 
   * 
   * @param {*} GDP the GDP value to be checked 
   */

  SetColour(GDP) {
    let mapFill = null;
    if (GDP >= 2) { mapFill = BlueDarkFill; }
    else if (GDP >= 1 && GDP < 2) { mapFill = BlueMediumFill; }
    else if (GDP > 0 && GDP < 1) { mapFill = BlueLightFill; }
    else if (GDP < 0 && GDP > -1) { mapFill = RedLightFill; }
    else if (GDP <= -1 && GDP > -2) { mapFill = RedMediumFill; }
    else if (GDP <= -2) { mapFill = RedDarkFill; }

    return mapFill;
  }

  /**
   * Updates dashboard graph. Function is sent as a prop to other components
   *
   * @param title: the new title of the graph
   * @param index: the new plotly index
   * @param image: the new preview image
   */

  ChangeGraph(title, index, image) {
    //Check that this is a new graph
    if (title !== this.state.graphTitle) {
      this.setState({ graphTitle: title, idx: index, previewImage: image });
    }
  }

  //Swap view. Called on toggle in the dashboard

  ChangeViewToggle() {
    if (this.refs.toggle.state.checked === true) {
      this.setState({ view: "Regional" });
      this.refs.toggle.state.checked = false
    } else {
      this.setState({ view: "National" });
      this.refs.toggle.state.checked = true
    }
  }

  //Swap view. Called on click of the national/regional buttons

  ChangeViewButtonClick(view) {
    if (view === "National") {
      this.setState({ view: "National" });
      this.refs.toggle.state.checked = true
    } else if (view === "Regional") {
      this.setState({ view: "Regional" });
      this.refs.toggle.state.checked = false
    }
  }





  render() {
    //Render NationalView component or RegionalView component depending on state
    /*
    let Dashboard = null;
    if (this.state.view === "National") {
      Dashboard = (
        <NationalView
          SVGViewport={this.state.SVGViewport}
          ChangeGraph={this.ChangeGraph}
          PredictionData={this.props.PredictionData}
          SetColour={this.SetColour}
        />
      );
    }*/
    const { open } = this.state;


    //set graphs
    //const { open } = this.state;
    let idx = this.state.idx;
    let plotlyFileName1 = this.props.IndicatorData['NonDimensional']['SPIndex']['Graphs']['Shared Prosperity Composite Index']['plotly_file_path'];
    let plotlyFileName2 = this.props.IndicatorData['NonDimensional']['SPIndex']['Graphs']['Annual Change By Dimension']['plotly_file_path'];
    let urlParametrs = ".embed?link=false&modebar=false&autosize=True";

    let total_indicators = 0;
    let keys = Object.keys(this.props.IndicatorData);
    for (let key = 0; key < keys.length - 1; key++) {
      //console.log('KEY: ' + key.toString());
      //console.log('DIM: ' + this.props.IndicatorData[keys[key]]['OfficialTitle']);
      total_indicators = total_indicators + Object.keys(this.props.IndicatorData[keys[key]]['Indicators']).length
      //console.log('total_indicators: ' + total_indicators.toString())
    }
    //console.log('KEYS: ' + Object.keys(this.props.IndicatorData))

    let descriptionText = " There are " + (Object.keys(this.props.IndicatorData).length - 1).toString() + " dimensions and " + total_indicators.toString() + " indicators."

    //console.log('this.state.idx:' +  this.state.idx);

    const mediumData = this.state.mediumPosts.slice(0, 3);
    const mediumBlog = mediumData.map((article, index) =>
      <Col md="4" className="mb-3" key={article.guid}>
        <div>
          <Card>
            <CardImg top width="100%" src={article.thumbnail} alt="img" />
            <CardBody>
              <CardTitle>
                <a href={article.link}>{article.title}</a>
              </CardTitle>
              <CardSubtitle>
                Published:{" "}
                {moment(article.pubDate).format("dddd, MMMM Do YYYY")}
              </CardSubtitle>
            </CardBody>
          </Card>
        </div>
      </Col>
    )

    return (
      <div id="top">
        <a id="top" />

        {/* Header includes menu, banner and overview sections */}

        <Header
          ChangeViewButtonClick={this.ChangeViewButtonClick}
          IndicatorData={this.props.IndicatorData}
        />
        {/* Scroll up button */}
        <div>
          <ScrollUpButton />

        </div>

        {/* SP Summary */}
        <main className="hoc container clear">
          <section>
            <h2 className="font-xs font-x3" style={{ color: "#474747" }}>Shared Prosperity Index New Zealand</h2>
            <ColoredLine spec={{ color: "#474747", hight: "5" }} />

            <p></p>

            <li className="one_half first">
              <article>
                <h4 className="font-xs font-x2" style={{ color: "#474747" }}>Overview of the Shared Prosperity Index</h4>
                <p>
                  <b>In the 1980s, New Zealand experienced comparatively high levels of sharing</b> with the highest score being 0.71 in 1986. It was during this time that New Zealand had a reputation of being one of the most egalitarian countries in the western world. Between 1987 and 1994, however, the level of sharing dropped steeply, falling from 0.71 to a low of 0.49. This reduction in sharing coincided with a period of political unrest, major economic changes and an economic recession from the mid 80s to the early 90s. After a snap election in 1984, the new government introduced sweeping reforms to liberalise and diversify the economy. These reforms continued under two different governments until 1993.
                </p><p>
                  <b>Between 1990 and 2000, the level of sharing in New Zealand was fairly constant</b> at a level of around 0.48 before rising again to a short term high of 0.55 in 2006. Again, the change in sharing seems to coincide with a change in economic performance.  In 1998, the New Zealand economy entered a period of significant growth that lasted until 2006. <b>Since 2007, the New Zealand Shared Prosperity Index has gradually decreased and has flattened out to around 0.5 in the last two years</b>. Remarkably, the considerable slowdown of economic growth during the Global Financial Crisis in 2007 and 2008 did not have a major impact on the level of sharing, but neither has the steady economic growth in recent years.
                </p><p>
                  Looking at the annual change of each dimension over time, no consistent pattern seems to appear (e.g. the socio-economic wellbeing dimension improved in 2013 and 2015 but worsened considerably in 2014). In 2018, we see a significant increase in the safety and security dimension and a mild decrease in the housing and health dimension.
                  Focusing now on the individual scores of a dimension, we observe the lowest level of sharing /inequality in the income and wealth dimension (0.38) which is in line with a global observation that in terms of wealth and income, the gap between the rich and poor is increasing. The employment dimension has the highest score (0.56) which is driven by the record highs in employment levels we currently have in New Zealand. Housing, health and education have scores just below the composite value while the deprivation, safety and security, as well as the general inequality dimensions sit above the composite value of 0.5.

                </p>
              </article>
            </li>

            <li className="one_half">
              <MDBRow>
                <MDBCol md="9"><h4 className="font-xs font-x2" style={{ color: "#474747" }}>Shared Prosperity Composite Index</h4>
                </MDBCol>
                <MDBCol md="1">
                  <a onClick={() => this.ChangeGraph(this.props.IndicatorData['NonDimensional']['SPIndex']['DisplayGraphs'][0], 0, null)}
                    style={{ display: "block", color: "black" }}
                    id={this.props.IndicatorData['NonDimensional']['SPIndex']['DisplayGraphs'][0]}
                  >
                    <MDBIcon
                      icon="expand"
                      size="1x"
                      className="pink-text "
                      onClick={this.onOpenModal}
                    ></MDBIcon>
                  </a>
                  <Modal className="styles_modal__gNwvD" onClose={this.onCloseModal} open={open} center >
                    <a id="graph" />
                    <h2> {this.props.IndicatorData['NonDimensional']['SPIndex']['DisplayGraphs'][this.state.idx]} </h2>
                    <iframe className="iframe" frameborder="0" scrolling="yes" src={this.props.IndicatorData['NonDimensional']['SPIndex']['Graphs'][this.props.IndicatorData['NonDimensional']['SPIndex']['DisplayGraphs'][this.state.idx]]['plotly_file_path'] + urlParametrs}></iframe>
                  </Modal>
                </MDBCol>
                <MDBCol md="1">
                  <CreateToolTip title={"Shared Prosperity Composite Index"} info={this.props.IndicatorData['NonDimensional']['SPIndex']['Graphs'][this.props.IndicatorData['NonDimensional']['SPIndex']['DisplayGraphs'][0]]['Description'] + descriptionText} />
                </MDBCol>
              </MDBRow>

              {/*  <iframe className = "iframe" title="test_chart" src= "https://plot.ly/~tsusnjak/281.embed?link=false&modebar=false&autosize=True"/> */}
              <div className="wrapper row3">
                <section style={{ height: "450px", paddingTop: "20px" }} >
                  <a id="graph" />
                  <iframe className="iframe" frameBorder="0" scrolling="no" src={plotlyFileName1 + urlParametrs}></iframe>
                </section>
              </div>
            </li>

            <li className="one_half">
              <MDBRow>
                <MDBCol md="9"><h4 className="font-xs font-x2" style={{ color: "#474747" }}>Annual Change By Dimension</h4>
                </MDBCol>


                <MDBCol md="1">
                  <a onClick={() => this.ChangeGraph(this.props.IndicatorData['NonDimensional']['SPIndex']['DisplayGraphs'][1], 1, null)}
                    style={{ display: "block", color: "black" }}
                    id={this.props.IndicatorData['NonDimensional']['SPIndex']['DisplayGraphs'][1]}
                  >
                    <MDBIcon
                      icon="expand"
                      size="1x"
                      className="pink-text "
                      onClick={this.onOpenModal}

                    ></MDBIcon> </a>
                  <Modal className="styles_modal__gNwvD" onClose={this.onCloseModal} open={open} center >
                    <a id="graph" />
                    <h2> {this.props.IndicatorData['NonDimensional']['SPIndex']['DisplayGraphs'][this.state.idx]} </h2>
                    <iframe className="iframe" frameborder="0" scrolling="yes" src={this.props.IndicatorData['NonDimensional']['SPIndex']['Graphs'][this.props.IndicatorData['NonDimensional']['SPIndex']['DisplayGraphs'][this.state.idx]]['plotly_file_path'] + urlParametrs}></iframe>
                  </Modal>
                </MDBCol>

                <MDBCol md="1">
                  <CreateToolTip title={"Annual Change By Dimension"} info={this.props.IndicatorData['NonDimensional']['SPIndex']['Graphs'][this.props.IndicatorData['NonDimensional']['SPIndex']['DisplayGraphs'][1]]['Description']} />
                </MDBCol>
              </MDBRow>

              <div className="wrapper row3">
                <section style={{ height: "450px", paddingTop: "20px" }} >
                  <a id="graph" />
                  <iframe className="iframe" frameBorder="0" scrolling="no" src={plotlyFileName2 + urlParametrs}></iframe>
                </section>
              </div>
            </li>


          </section>
        </main>

        <main className="hoc container clear">
        </main>

        <Accordion className='hoc container clear accordion' accordion={false}>
          <AccordionItem expanded={true}>
            <AccordionItemTitle role={"tab"}>
              <main className="hoc container clear">
                <a id={this.props.IndicatorData['IncomeAndWealth']['OfficialTitle']} />
                <h2 className="font-xs font-x3" style={{ color: "#474747" }}>{this.props.IndicatorData['IncomeAndWealth']['OfficialTitle']}</h2>
                <ColoredLine spec={{ color: "#474747", hight: "3" }} />
              </main>
            </AccordionItemTitle>
            <AccordionItemBody>
              <DimensionTemplate IndicatorData={this.props.IndicatorData['IncomeAndWealth']} DimensionKey='IncomeAndWealth' />
            </AccordionItemBody>
          </AccordionItem>


          <AccordionItem expanded={true}>
            <AccordionItemTitle role={"tab"}>
              <main className="hoc container clear">
                <a id={this.props.IndicatorData['Labour']['OfficialTitle']} />
                <h2 className="font-xs font-x3" style={{ color: "#474747" }}>{this.props.IndicatorData['Labour']['OfficialTitle']}</h2>
                <ColoredLine spec={{ color: "#474747", hight: "3" }} />
              </main>
            </AccordionItemTitle>
            <AccordionItemBody>
              <DimensionTemplate IndicatorData={this.props.IndicatorData['Labour']} DimensionKey='Labour' />
            </AccordionItemBody>
          </AccordionItem>


          <AccordionItem expanded={true}>
            <AccordionItemTitle>
              <main className="hoc container clear">
                <a id={this.props.IndicatorData['Housing']['OfficialTitle']} />
                <h2 className="font-xs font-x3" style={{ color: "#474747" }}>{this.props.IndicatorData['Housing']['OfficialTitle']}</h2>
                <ColoredLine spec={{ color: "#474747", hight: "3" }} />
              </main>
            </AccordionItemTitle>
            <AccordionItemBody>
              <DimensionTemplate IndicatorData={this.props.IndicatorData['Housing']} DimensionKey='Housing' />
            </AccordionItemBody>
          </AccordionItem>

          <AccordionItem expanded={true}>
            <AccordionItemTitle>
              <main className="hoc container clear">
                <a id={this.props.IndicatorData['Health']['OfficialTitle']} />
                <h2 className="font-xs font-x3" style={{ color: "#474747" }}>{this.props.IndicatorData['Health']['OfficialTitle']}</h2>
                <ColoredLine spec={{ color: "#474747", hight: "3" }} />
              </main>
            </AccordionItemTitle>
            <AccordionItemBody>
              <DimensionTemplate IndicatorData={this.props.IndicatorData['Health']} DimensionKey='Health' />
            </AccordionItemBody>
          </AccordionItem>

          <AccordionItem expanded={true}>
            <AccordionItemTitle>
              <main className="hoc container clear">
                <a id={this.props.IndicatorData['Poverty']['OfficialTitle']} />
                <h2 className="font-xs font-x3" style={{ color: "#474747" }}>{this.props.IndicatorData['Poverty']['OfficialTitle']}</h2>
                <ColoredLine spec={{ color: "#474747", hight: "3" }} />
              </main>
            </AccordionItemTitle>
            <AccordionItemBody>
              <DimensionTemplate IndicatorData={this.props.IndicatorData['Poverty']} DimensionKey='Poverty' />
            </AccordionItemBody>
          </AccordionItem>

          <AccordionItem expanded={true}>
            <AccordionItemTitle>
              <main className="hoc container clear">
                <a id={this.props.IndicatorData['Education']['OfficialTitle']} />
                <h2 className="font-xs font-x3" style={{ color: "#474747" }}>{this.props.IndicatorData['Education']['OfficialTitle']}</h2>
                <ColoredLine spec={{ color: "#474747", hight: "3" }} />
              </main>
            </AccordionItemTitle>
            <AccordionItemBody>
              <DimensionTemplate IndicatorData={this.props.IndicatorData['Education']} DimensionKey='Education' />
            </AccordionItemBody>
          </AccordionItem>

          <AccordionItem expanded={true}>
            <AccordionItemTitle>
              <main className="hoc container clear">
                <a id={this.props.IndicatorData['SafetyAndSecurity']['OfficialTitle']} />
                <h2 className="font-xs font-x3" style={{ color: "#474747" }}>{this.props.IndicatorData['SafetyAndSecurity']['OfficialTitle']}</h2>
                <ColoredLine spec={{ color: "#474747", hight: "3" }} />
              </main>
            </AccordionItemTitle>
            <AccordionItemBody>
              <DimensionTemplate IndicatorData={this.props.IndicatorData['SafetyAndSecurity']} DimensionKey='SafetyAndSecurity' />
            </AccordionItemBody>
          </AccordionItem>


          <AccordionItem expanded={true}>
            <AccordionItemTitle>
              <main className="hoc container clear">
                <a id={this.props.IndicatorData['GeneralInequality']['OfficialTitle']} />
                <h2 className="font-xs font-x3" style={{ color: "#474747" }}>{this.props.IndicatorData['GeneralInequality']['OfficialTitle']}</h2>
                <ColoredLine spec={{ color: "#474747", hight: "3" }} />
              </main>
            </AccordionItemTitle>
            <AccordionItemBody>
              <DimensionTemplate IndicatorData={this.props.IndicatorData['GeneralInequality']} DimensionKey='GeneralInequality' />
            </AccordionItemBody>
          </AccordionItem>
        </Accordion>


        {/* Start of Inputs Display */}
        {/*
        <a id="inputs" />
        <InputsDisplay 
        isMobileDevice={this.state.isMobileDevice}
        PlotlyIndex = {this.props.PlotlyIndex}
        /> */}
        {/* End of Inputs Display */}

        <div className="split clear">
          <h3 className="font-xs font-x3" style={{ color: "#474747" }}>Latest Posts</h3>
          <ColoredLine spec={{ color: "#474747", hight: "5" }} />
          <div className="wrapper row3">
            <div className="split clear">
              <article id="blogposts" className="one_third first" style={{ width: "100%" }}>
              </article>
              {mediumBlog}
            </div>
            < div className="split clear" >
            </div>
          </div>

        </div>
        {/* Footer */}
        <Footer />
      </div>



    );
  }
}

const ColoredLine = ({ spec }) => (
  <hr
    style={{
      color: spec.color,
      backgroundColor: spec.color,
      height: parseInt(spec.hight)
    }}
  />
);

export default Dashboard;
