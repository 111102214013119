import React from "react";

//Footer background image
import FooterImage from "../images/footer_alps.jpg";
import { HashLink as Link } from 'react-router-hash-link';

/**
 * Footer Component
 *  - functional component to display website footer
 * @param {*} props 
 */


function Footer(props) {
  return (
    <div>
      <div
        className="bgded overlay"
        //Set component background image
        style={{ backgroundImage: `url(${FooterImage})` }}
      >
        <div className="wrapper row4">
          <footer id="footer" className="hoc clear">
            <div className="one_half first">
              <h6 className="title"> Knowledge Exchange Hub</h6>
              <p>
                We would love to hear from you if you have any feedback about the Shared Prosperity Index (SPI) Project.  Our data comes from multiple sources which are subject to revisions and updates. Please let us know if you uncover any discrepancies in the data. Also, if you believe that you have access to data which could be of benefit to this project, then please contact us.            </p>
              <p>
                Please contact us if you would like to discuss a specific consulting engagement with the Knowledge Exchange Hub, or would like a customised analysis or presentation about SPI in the context of your business or industry.
              </p>
              <p>
                We also encourage you to share the information and analysis contained in this website. For information about how to do this, please refer to our
                <Link to="/about#TermsOfUse"> Terms of use.
                </Link>
              </p>
            </div>
            <div className="one_half">
              <h6 className="title"></h6>
              <ul className="nospace linklist contact">
                <li>
                  <i className="fa fa-map-marker" />
                  <address>
                    Massey University Albany Campus, <br></br>Dairy Flat Highway (SH17), Auckland 0632, New Zealand
                  </address>
                </li>
                <li>
                  Christoph Schumacher (Director)
                  <br></br>
                  <a className="fa fa-envelope"></a> &nbsp;  c.schumacher@massey.ac.nz

                </li>
                <li>
                  Teo Susnjak (Data Scientist & Web Developer)
                  <br></br>
                  <a className="fa fa-envelope"></a> &nbsp;  t.susnjak@massey.ac.nz

                </li>
                <div className="socialMedia">


                  {/*<a className="faicon-linkedin" href="https://www.linkedin.com/company/gdplive/" >
                    <i className="fa fa-linkedin" />
                  </a>*/}

                  <a className="faicon-twitter" href="https://twitter.com/SPI_NewZealand">
                    <i className="fa fa-twitter" />
                  </a>

                </div>
              </ul>
            </div>
            {/* <div className="one_quarter">
              <h6 className="title">Non vestibulum rutrum</h6>
              <ul className="nospace linklist">
                <li>
                  <a href="">Varius nullam a enim ac</a>
                </li>
                <li>
                  <a href="">Justo iaculis congue eu vel</a>
                </li>
                <li>
                  <a href="">Aliquam pulvinar fermentum</a>
                </li>
                <li>
                  <a href="">Lacus condimentum elementum</a>
                </li>
                <li>
                  <a href="">Vel nulla massa curabitur</a>
                </li>
              </ul>
            </div> */}
            {/* <div className="one_third">
              <h6 className="title">Imperdiet libero vitae</h6>
              <ul className="nospace linklist">
                <li>
                  <article>
                    <h6 className="nospace font-x1">
                      <a href="">Fermentum mauris</a>
                    </h6>
                    <time
                      className="font-xs block btmspace-10"
                      dateTime="2045-04-06"
                    >
                      Friday, 6<sup>th</sup> April 2045
                    </time>
                    <p className="nospace">
                      Sit amet pharetra mauris eros ut turpis curabitur
                      molestie&hellip;
                    </p>
                  </article>
                </li>
                <li>
                  <article>
                    <h6 className="nospace font-x1">
                      <a href="">Enim tempor neque</a>
                    </h6>
                    <time
                      className="font-xs block btmspace-10"
                      dateTime="2045-04-05"
                    >
                      Thursday, 5<sup>th</sup> April 2045
                    </time>
                    <p className="nospace">
                      Hendrerit justo cras in nulla a mi aliquet tempus
                      integer&hellip;
                    </p>
                  </article>
                </li>
              </ul>
            </div> */}
          </footer>
        </div>
        <div className="wrapper row5">
          <div id="copyright" className="hoc clear">
            <p className="fl_left">
              Copyright &copy; 2019 - Massey University - All Rights Reserved.
              <a href=""></a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
